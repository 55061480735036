import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  type MenuProps,
} from '@mui/material';
import type { FC, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

export type ButtonFactory = (
  handleClick: MouseEventHandler<HTMLElement>,
) => ReactElement;

export interface ConfirmMenuItemProps {
  key: string;
  confirm?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  hidden?: boolean;
  label: ReactNode;
  onClick: MouseEventHandler<HTMLElement>;
  shortcutKey?: ReactNode;
}

interface Props extends Omit<MenuProps, 'anchorEl' | 'open' | 'onClose'> {
  button: ButtonFactory;
  horizontalAlign?: 'left' | 'center' | 'right' | number;
  items: ConfirmMenuItemProps[];
}

const ConfirmMenuItem: FC<ConfirmMenuItemProps> = (props) => {
  const [sure, setConfirm] = useState(false);

  if (sure) {
    return (
      <MenuItem
        disabled={props.disabled}
        onClick={(e) => {
          setConfirm(false);
          props.onClick(e);
        }}
        sx={{
          '&:hover': {
            backgroundColor: (theme) => theme.palette.error.dark,
            color: (theme) => theme.palette.error.contrastText,
          },
          backgroundColor: (theme) => theme.palette.error.main,
          color: (theme) => theme.palette.error.contrastText,
        }}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText>Sure?</ListItemText>
      </MenuItem>
    );
  }

  return (
    <MenuItem
      disabled={props.disabled || !props.onClick}
      onClick={
        props.confirm
          ? () => {
              setConfirm(true);
            }
          : props.onClick
      }
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText>{props.label}</ListItemText>
      {props.shortcutKey ? (
        <Typography variant="body2" color="text.secondary">
          {props.shortcutKey}
        </Typography>
      ) : null}
    </MenuItem>
  );
};

export const MenuController: FC<Props> = ({
  button,
  horizontalAlign,
  items,
  ...props
}) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu =
    (action?: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (event) => {
      setAnchorElUser(null);
      action?.(event);
    };

  return (
    <>
      {button(handleOpenUserMenu)}
      <Menu
        PaperProps={{ sx: { minWidth: 240 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalAlign ?? 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalAlign ?? 'left',
        }}
        {...props}
        anchorEl={anchorElUser}
        onClose={handleCloseUserMenu()}
        open={Boolean(anchorElUser)}
      >
        {items
          .filter((item) => !item.hidden)
          .map((item) => (
            <ConfirmMenuItem
              {...item}
              onClick={handleCloseUserMenu(item.onClick)}
            />
          ))}
      </Menu>
    </>
  );
};
